import React, { useState, useEffect, useRef } from "react";
import { Table } from "../../components/Table/Table";
import {
  delete_icon,
  edit_icon,
  eye_icon,
  password_icon,
  profile,
  toggle_off,
  toggle_on,
  view_icon,
} from "../../assets/imgs";
import apiRequest from "../redux/api/apiRequest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { toast } from "react-toastify";
import {
  useListUserMutation,
  useAddUserMutation,
  useUpdateUserMutation,
  useLazyViewUserQuery,
  useLazyUserStatusQuery,
} from "../redux/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { admin_list } from "../redux/api/dummyJson";
import CancelIcon from "@mui/icons-material/Cancel";
import { EMAIL } from "../redux/api/Constants";
import { cityList, countriesList, stateList } from "../redux/api/Country";

const UsersList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(false);
  const [viewpopup, setViewPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const givenpermissions = JSON.parse(localStorage.getItem("user_permission"));
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState("");
  const [type, setType] = useState();
  const [tabs, setTabs] = useState(1);
  const [page, setPage] = useState(1);
  const [deviceList, setDeviceList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [devicepop, setDevicePop] = useState(false);
  const search = useRef(null);
  const [userData, setUserData] = useState({
    email: "",
    name: "",
    phone: "",
    city: "",
    state: "",
    country: "",
  });
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  console.log(countryId, stateId, Number(cityId));

  const [listUser] = useListUserMutation();
  const [userStatus] = useLazyUserStatusQuery();
  const [viewUser] = useLazyViewUserQuery();
  const [addUser] = useAddUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const getList = async (search) => {
    setList([]);
    const formdata = new FormData();
    formdata.append("rows", 10);
    formdata.append("page", page);
    formdata.append("type", tabs);
    formdata.append("search", search ? search : "");
    formdata.append("order", "asc");
    const res = await apiRequest(listUser, formdata);
    if (res?.status == "success") {
      setList(res?.lists);
      setPagination(res?.pagination_meta);
    }
  };

  useEffect(() => {
    getList();
  }, [page]);

  useEffect(() => {
    setPage(1);
    getList();
  }, [tabs]);

  const userView = async (id) => {
    setPopup(true);
    const res = await apiRequest(viewUser, id);
    if (res?.status == "success") {
      setUserData(res?.user_data);
      let country = countriesList?.find(
        (item) => item?.name == res?.user_data?.country
      );
      let state = stateList?.find(
        (item) => item?.name == res?.user_data?.state
      );
      let city = cityList?.find((item) => item?.name == res?.user_data?.city);
      console.log(city, state, country);
      setCityId(Number(city?.id));
      setStateId(Number(state?.id));
      setCountryId(Number(country?.id));
    }
    console.log(res);
  };

  const Add = async () => {
    if (
      !userData?.name ||
      userData?.phone?.length < 8 ||
      !userData?.email ||
      !userData?.email.match(EMAIL)
    ) {
      setError(true);
      return;
    }
    if (type == "add") {
      if (!userData?.city || !userData?.state || !userData?.country) {
        setError(true);
        return;
      }
    }
    setError(false);
    const formdata = new FormData();
    if (type == "edit") {
      formdata.append("user_id", userData?.id);
    }
    formdata.append("name", userData?.name);
    formdata.append("email", userData?.email);
    formdata.append("phone", userData?.phone);
    formdata.append("city", userData?.city);
    formdata.append("state", userData?.state);
    formdata.append("country", userData?.country);
    setLoader(true);
    const res = await apiRequest(
      type == "add" ? addUser : updateUser,
      formdata
    );
    setLoader(false);
    setPopup(false);
    if (res?.status == "success") {
      toast.success(res?.message);
    } else {
      toast.error("Error");
    }
    setUserData({});
    getList();
  };

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  console.log(userData);

  const handleSelect = (e) => {
    const { name, value } = e.target;
    let val = (
      name == "country" ? countriesList : name == "state" ? stateList : cityList
    )?.find((item) => {
      if (item?.id == value) {
        return true;
      }
    });
    if (name == "country") {
      setCountryId(Number(value));
      setStateId("");
      setCityId("");
    } else if (name == "state") {
      setStateId(Number(value));
      setCityId("");
    } else {
      setCityId(Number(value));
    }

    console.log(val, name, value);
    setUserData((prevState) => ({
      ...prevState,
      [name]: val?.name,
    }));
  };

  const handleStatus = async (id) => {
    const res = await apiRequest(userStatus, id);
    if (res?.status == "success") {
      toast.success(res?.message);
      getList();
    }
  };

  const Assigned = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: " Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: " Email",
        accessor: "email",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Number",
        accessor: "phone",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "View Device Details",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => {
                if (givenpermissions?.includes("CAN-View-USERS")) {
                  userView(row.original.id);
                  setType("view");
                }
              }}
              className="cust-btn "
            >
              <img src={view_icon} alt="" className="mx-2 edit-btn " />
            </button>
          );
        },
      },
      {
        Header: "View Devices as Member ",
        accessor: "member_devices",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => {
                if (value?.length != 0) {
                  setDeviceList(value);
                  setDevicePop(true);
                }
              }}
              className="cust-btn "
            >
              {value?.length != 0 ? (
                <img src={view_icon} alt="" className="mx-2 edit-btn " />
              ) : (
                "-"
              )}
            </button>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <div>
              <button
                onClick={() => {
                  if (givenpermissions?.includes("CAN-Status-USERS")) {
                    handleStatus(row.original.id);
                  }
                }}
                className={`border-0 ms-2 cust-btn `}
              >
                {value == 1 ? (
                  <img className="toggle-btn" src={toggle_on} />
                ) : (
                  <img className="toggle-btn" src={toggle_off} />
                )}
              </button>
            </div>
          );
        },
      },

      // {
      //   Header: "Action",
      //   accessor: "status",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <React.Fragment>
      //         <button
      //           onClick={() => handleChange(row.original.id)}
      //           className={`border-0 toggle-btn cust-btn `}
      //         >
      //           {value ? (
      //             <div>
      //               <img className="w-50" src={toggle_on} />
      //             </div>
      //           ) : (
      //             <div>
      //               <img className="w-50" src={toggle_off} />
      //             </div>
      //           )}
      //         </button>
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex ac-jc">
                {givenpermissions?.includes("CAN-Edit-USERS") && (
                  <button
                    onClick={() => {
                      userView(row.original.id);
                      setType("edit");
                    }}
                    className="cust-btn"
                  >
                    <img src={edit_icon} alt="" className="mx-2 edit-btn " />
                  </button>
                )}
                {/* <button
                                    onClick={() => setDeletePopup(true)}
                                    className="cust-btn "
                                >
                                    <img src={delete_icon} alt="" className="mx-2 edit-btn " />
                                </button> */}
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  const NotAssigned = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: " Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: " Email",
        accessor: "email",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Number",
        accessor: "phone",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <div>
              <button
                onClick={() => {
                  if (givenpermissions?.includes("CAN-Status-USERS")) {
                    handleStatus(row.original.id);
                  }
                }}
                className={`border-0 ms-2 cust-btn `}
              >
                {value == 1 ? (
                  <img className="toggle-btn" src={toggle_on} />
                ) : (
                  <img className="toggle-btn" src={toggle_off} />
                )}
              </button>
            </div>
          );
        },
      },

      // {
      //   Header: "Action",
      //   accessor: "status",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <React.Fragment>
      //         <button
      //           onClick={() => handleChange(row.original.id)}
      //           className={`border-0 toggle-btn cust-btn `}
      //         >
      //           {value ? (
      //             <div>
      //               <img className="w-50" src={toggle_on} />
      //             </div>
      //           ) : (
      //             <div>
      //               <img className="w-50" src={toggle_off} />
      //             </div>
      //           )}
      //         </button>
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex ac-jc">
                {givenpermissions?.includes("CAN-Edit-USERS") && (
                  <button
                    onClick={() => {
                      userView(row.original.id);
                      setType("edit");
                    }}
                    className="cust-btn"
                  >
                    <img src={edit_icon} alt="" className="mx-2 edit-btn " />
                  </button>
                )}
                {/* <button
                                    onClick={() => setDeletePopup(true)}
                                    className="cust-btn "
                                >
                                    <img src={delete_icon} alt="" className="mx-2 edit-btn " />
                                </button> */}
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  const Members = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Photo",
        accessor: "profile_url",
        Cell: ({ value, row }) => {
          return (
            <div
              onClick={() => {
                if (!value) {
                  return;
                }
                setImageUrl(value);
              }}
            >
              <img
                src={value ? value : profile}
                className="vip-image pointer"
              />
            </div>
          );
        },
      },
      {
        Header: " Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Number",
        accessor: "phone",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: " Email",
        accessor: "email",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "View Device Details",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => {
                setDeviceList(row.original.member_devices);
                setDevicePop(true);
              }}
              className="cust-btn "
            >
              <img src={view_icon} alt="" className="mx-2 edit-btn " />
            </button>
          );
        },
      },
      {
        Header: " City",
        accessor: "city",
        Cell: ({ value, row }) => {
          return <p>{value ? value : "--"}</p>;
        },
      },
      {
        Header: " state",
        accessor: "state",
        Cell: ({ value, row }) => {
          return <p>{value ? value : "--"}</p>;
        },
      },
      {
        Header: " Country",
        accessor: "country",
        Cell: ({ value, row }) => {
          return <p>{value ? value : "--"}</p>;
        },
      },
    ],
    []
  );

  console.log(deviceList);

  return (
    <div className="dashRightView home_section">
      <div className="d-flex ac-jb">
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
          User List
        </h2>
        {givenpermissions?.includes("CAN-Add-USERS") && (
          <button
            onClick={() => {
              setPopup(true);
              setType("add");
            }}
            className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 white add-btn "
          >
            {" "}
            + Add User
          </button>
        )}
      </div>
      <div className="d-flex ac-js mt-2 ">
        <h4 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary me-3">
          Search
        </h4>
        <input
          type="text"
          className="input-pop ps-2"
          placeholder=" Search"
          ref={search}
          onChange={(e) => {
            getList(e.target.value);
          }}
        />
      </div>
      <div className="d-flex tab-design">
        <button
          onClick={() => {
            setTabs(1);
          }}
          className={`${
            tabs == 1 ? "left-active-tab-design" : ""
          } tab_inactive`}
        >
          Device owner
        </button>
        <button
          onClick={() => {
            setTabs(2);
          }}
          className={`${
            tabs == 2 ? "center-active-tab-design" : ""
          } tab_inactive`}
        >
          Unassigned users
        </button>
        <button
          onClick={() => {
            setTabs(3);
          }}
          className={`${
            tabs == 3 ? "right-active-tab-design" : ""
          } tab_inactive`}
        >
          Members
        </button>
      </div>
      {popup && (
        <div className="popup-design">
          <div className="popup">
            <div className="d-flex ac-jb">
              <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
                User {type == "view" ? "View" : type == "add" ? "Add" : "Edit"}
              </h2>
              <button
                onClick={() => {
                  setPopup(false);
                  setUserData({});
                  setCountryId("");
                  setStateId("");
                  setCityId("");
                }}
                className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 primary cust-btn"
              >
                <CancelIcon />
              </button>
            </div>
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Email
            </h4>
            <input
              type="email"
              className="input-pop ps-2 w-100"
              placeholder=" Email Address"
              name="email"
              onChange={(e) => {
                handleUpdate(e);
              }}
              value={userData?.email}
              disabled={type == "view" || type == "edit" ? true : false}
            />
            {error && (!userData?.email || !userData?.email?.match(EMAIL)) && (
              <p className="err">Enter Valid Email</p>
            )}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Name
            </h4>
            <input
              type="text"
              className="input-pop ps-2 w-100"
              placeholder=" Name"
              name="name"
              onChange={(e) => {
                handleUpdate(e);
              }}
              value={userData?.name}
              disabled={type == "view" ? true : false}
            />
            {error && !userData?.name && <p className="err">Enter Name</p>}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Phone Number
            </h4>
            <input
              type="number"
              className="input-pop ps-2 w-100"
              placeholder="Phone Number"
              name="phone"
              onChange={(e) => {
                // if (e.target.value.length <= 10) {
                handleUpdate(e);
                // }
              }}
              value={userData?.phone}
              disabled={type == "view" ? true : false}
            />
            {error && userData?.phone?.length < 8 && (
              <p className="err">Enter Valid Phone Number</p>
            )}
            <div>
              <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                Country
              </h4>

              {/* <input
                type="number"
                className="input-pop ps-2 w-100"
                placeholder="Phone Number"
                name="phone"
                onChange={(e) => {
                  handleUpdate(e);
                }}
              />
              {
                <div
                  style={{
                    overflow: "scroll",
                    height: 200,
                    borderWidth: 1,
                    borderColor: "green",
                    borderstyle: "solid",
                  }}
                >
                  <ul>
                    {countriesList?.map((item) => {
                      return <li> {item?.name}</li>;
                    })}
                  </ul>
                </div>
              } */}
            </div>
            <select
              className="input-pop ps-2 w-100"
              name="country"
              onChange={(e) => {
                handleSelect(e);
              }}
              value={countryId}
              disabled={type == "view" ? true : false}
            >
              <option value={""}> -- Select --</option>
              {countriesList?.map((item) => {
                return <option value={item?.id}> {item?.name}</option>;
              })}
            </select>
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              State
            </h4>
            <select
              className="input-pop ps-2 w-100"
              name="state"
              onChange={(e) => {
                handleSelect(e);
              }}
              value={stateId}
              disabled={type == "view" || !countryId ? true : false}
            >
              <option value={""}> -- Select --</option>
              {stateList
                ?.filter((data) => data?.country_id == countryId)
                ?.map((item) => {
                  return <option value={item?.id}> {item?.name}</option>;
                })}
            </select>
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              City
            </h4>
            <select
              className="input-pop ps-2 w-100"
              name="city"
              onChange={(e) => {
                handleSelect(e);
              }}
              value={cityId}
              disabled={type == "view" || !countryId || !stateId ? true : false}
            >
              <option value={""} className="">
                {" "}
                -- Select --
              </option>
              {cityList
                ?.filter((data) => data?.state_id == stateId)
                ?.map((item) => {
                  return <option value={item?.id}> {item?.name}</option>;
                })}
            </select>
            {/* <div className="d-flex ac-jb ">
              <div>
                <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                  State
                </h4>
                <select
                  className="input-pop ps-2 w-100"
                  name="state"
                  onChange={(e) => {
                    handleSelect(e);
                  }}
                  value={stateId}
                  disabled={popup == "view" || !countryId ? true : false}
                >
                  <option value={""}> -- Select --</option>
                  {stateList
                    ?.filter((data) => data?.country_id == countryId)
                    ?.map((item) => {
                      return <option value={item?.id}> {item?.name}</option>;
                    })}
                </select>
                {error && !userData?.state && (
                  <p className="err">Enter State</p>
                )}
              </div>
              <div>
                <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                  City
                </h4>
                <select
                  className="input-pop ps-2 w-100"
                  name="city"
                  onChange={(e) => {
                    handleSelect(e);
                  }}
                  value={cityId}
                  disabled={
                    popup == "view" || !countryId || !stateId ? true : false
                  }
                >
                  <option value={""}> -- Select --</option>
                  {cityList
                    ?.filter((data) => data?.state_id == stateId)
                    ?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                </select>
                {error && !userData?.city && <p className="err">Enter City</p>}
              </div>
            </div> */}
            {error && !userData?.country && (
              <p className="err">Enter Country</p>
            )}
            {type == "view" && (
              <div>
                {/* <div className="d-flex">
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      Device 1
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-90"
                      placeholder="Device"
                    />
                  </div>
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      Location
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-90"
                      placeholder="Location"
                    />
                  </div>
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      No of Members
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-80"
                      placeholder="15"
                    />
                  </div>
                </div> */}
                {userData?.devices?.map((item, index) => {
                  return (
                    <div key={index} className="d-flex sm-flex-column">
                      <div>
                        <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                          Device {index + 1}
                        </h4>
                        <input
                          type="text"
                          className="input-pop ps-2 w-90"
                          placeholder="Device"
                          value={item?.device_name}
                          disabled
                        />
                      </div>
                      <div>
                        <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                          Location
                        </h4>
                        <input
                          type="text"
                          className="input-pop ps-2 w-90"
                          placeholder="Location"
                          value={item?.location_name}
                          disabled
                        />
                      </div>
                      <div>
                        <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                          No of Members
                        </h4>
                        <input
                          type="text"
                          className="input-pop ps-2 w-80"
                          placeholder="20"
                          value={item?.members_count}
                          disabled
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {type != "view" && (
              <div className="d-flex ac-jc mt-3">
                <button
                  onClick={() => {
                    Add();
                  }}
                  className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mt-3 "
                >
                  {loader && (
                    <div
                      class="spinner-border spinner-border-sm "
                      role="status"
                      style={{ marginRight: "5px" }}
                    ></div>
                  )}
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {devicepop && (
        <div className="popup-design">
          <div className="popup" style={{ width: 800 }}>
            <div className="d-flex ac-jb">
              <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
                View Devices
              </h2>
              <button
                onClick={() => {
                  setDevicePop(false);
                }}
                className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 primary cust-btn"
              >
                <CancelIcon />
              </button>
            </div>
            <div>
              {deviceList?.length == 0 ? (
                <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 mt-5 text-center primary">
                  No Devices Found
                </h2>
              ) : (
                deviceList?.map((item, index) => {
                  return (
                    <div key={index} className="d-flex sm-flex-column">
                      <div>
                        <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                          Device {index + 1}
                        </h4>
                        <input
                          type="text"
                          className="input-pop ps-2 w-90"
                          placeholder="Device"
                          value={item?.device_name}
                          disabled
                        />
                      </div>
                      <div>
                        <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                          Location
                        </h4>
                        <input
                          type="text"
                          className="input-pop ps-2 w-90"
                          placeholder="Location"
                          value={item?.location_name}
                          disabled
                        />
                      </div>
                      <div className="w-50">
                        <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                          Device owner
                        </h4>
                        <input
                          type="text"
                          className="input-pop ps-2 w-100"
                          placeholder="20"
                          value={`${item?.device_owner?.name} (${item?.device_owner?.email})`}
                          disabled
                        />
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}

      <Table
        columns={tabs == 1 ? Assigned : tabs == 2 ? NotAssigned : Members}
        data={list}
        show={true}
        pagecount={pagination}
        setPageno={setPage}
      />
      {imageUrl && (
        <div className="image-popup-design">
          <div className="img-popup">
            <div
              className="d-flex ac-je w-100 pointer"
              onClick={() => setImageUrl(false)}
            >
              <HighlightOffIcon />
            </div>
            <img src={imageUrl} className="image-pop" alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersList;
