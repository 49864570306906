import React from "react";
import { Route, Routes } from "react-router-dom";
import "../src/assets/style/color.scss";
import "../src/assets/style/custome.scss";
import "../src/assets/style/fonts.scss";
import "../src/assets/style/height.scss";
import "../src/assets/style/responsive.scss";
import "../src/assets/style/style.scss";
import "../src/assets/style/width.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";
import UserList from "./screens/AdminList/AdminList";
import Layout from "./components/Layouts/Layout";
import Login from "./screens/Login/Login";
import Registration from "./screens/Login/Registration";
import Role from "./screens/Role/Role";
import AddRole from "./screens/Role/AddRole";
import UsersList from "./screens/UserList/UsersList";
import DeviceList from "./screens/DeviceList/DeviceList";
import DeviceAdd from "./screens/DeviceList/DeviceAdd";
import LocationMarks from "./screens/Locations/LocationMarks";
import ResetPassword from "./screens/Login/ResetPassword";
import MembersList from "./screens/UserList/MembersList";
import Report from "./screens/Report/Report";
import NotificationReport from "./screens/Report/NotificationReport";

const ReactRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route element={<Layout />}>
        <Route path="/admin-list" element={<UserList />} />
        <Route path="/role" element={<Role />} />
        <Route path="/add-role" element={<AddRole />} />
        <Route path="/user-list" element={<UsersList />} />
        <Route path="/members-list" element={<MembersList />} />
        <Route path="/device-list" element={<DeviceList />} />
        <Route path="/device-add" element={<DeviceAdd />} />
        <Route path="/report" element={<Report />} />
        <Route path="/notificationreport" element={<NotificationReport />} />
        <Route path="/location-marks" element={<LocationMarks />} />
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
