import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, TOKEN, URL } from "./Constants";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem(TOKEN);
      headers.set("Authorization", "Bearer " + token);
      // headers.set("Content-Type", "multipart/form-data");
      // headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        body: payload,
        method: "POST",
      }),
    }),

    // LOGOUT
    logout: builder.query({
      query: () => URL.LOGOUT,
    }),

    //FORGOT PASSWORD
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: URL.FORGOT_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    //VERIFY TOKEN
    verifyToken: builder.query({
      query: (id) => URL.TOKEN_VERIFY + id,
    }),

    //SET PASSWORD
    changePassword: builder.mutation({
      query: (payload) => ({
        url: URL.CHANGE_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    //ADMIN

    addAdmin: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_ADMIN,
        body: payload,
        method: "POST",
      }),
    }),
    updateAdmin: builder.mutation({
      query: (payload) => ({
        url: URL.UPDATE_ADMIN,
        body: payload,
        method: "POST",
      }),
    }),

    viewAdmin: builder.query({
      query: (id) => URL.VIEW_ADMIN + id,
    }),

    deleteAdmin: builder.query({
      query: (id) => URL.DELETE_ADMIN + id,
    }),

    adminStatus: builder.query({
      query: (id) => URL.ADMIN_STATUS + id,
    }),

    listAdmin: builder.mutation({
      query: (payload) => ({
        url: URL.LIST_ADMIN,
        body: payload,
        method: "POST",
      }),
    }),

    //ROLE

    permissionsList: builder.query({
      query: () => URL.PERMISSIONS_LIST,
    }),

    listRole: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE_LIST,
        body: payload,
        method: "POST",
      }),
    }),

    addRole: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_ROLE,
        body: payload,
        method: "POST",
      }),
    }),

    updateRole: builder.mutation({
      query: (payload) => ({
        url: URL.UPDATE_ROLE,
        body: payload,
        method: "POST",
      }),
    }),

    viewRole: builder.query({
      query: (id) => URL.VIEW_ROLE + id,
    }),

    deleteRole: builder.query({
      query: (id) => URL.DELETE_ROLE + id,
    }),
    roleStatus: builder.query({
      query: (id) => URL.ROLE_STATUS + id,
    }),

    //USER LIST

    listUser: builder.mutation({
      query: (payload) => ({
        url: URL.USER_LIST,
        body: payload,
        method: "POST",
      }),
    }),

    addUser: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_USER,
        body: payload,
        method: "POST",
      }),
    }),

    updateUser: builder.mutation({
      query: (payload) => ({
        url: URL.UPDATE_USER,
        body: payload,
        method: "POST",
      }),
    }),

    viewUser: builder.query({
      query: (id) => URL.VIEW_USER + id,
    }),

    userStatus: builder.query({
      query: (id) => URL.USER_STATUS + id,
    }),

    //DEVICE LIST

    deviceList: builder.mutation({
      query: (payload) => ({
        url: URL.DEVICE_LIST,
        body: payload,
        method: "POST",
      }),
    }),

    deviceUserList: builder.mutation({
      query: (payload) => ({
        url: URL.DEVICE_USER_LIST,
        body: payload,
        method: "POST",
      }),
    }),

    addDevice: builder.mutation({
      query: (payload) => ({
        url: URL.DEVICE_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    updateDevice: builder.mutation({
      query: (payload) => ({
        url: URL.DEVICE_UPDATE,
        body: payload,
        method: "POST",
      }),
    }),

    deviceReport: builder.mutation({
      query: (payload) => ({
        url: URL.DEVICE_REPORT,
        body: payload,
        method: "POST",
      }),
    }),
    deviceReportExport: builder.mutation({
      query: (payload) => ({
        url: URL.DEVICE_REPORT_EXPORT,
        body: payload,
        method: "POST",
      }),
    }),

    notificationReport: builder.mutation({
      query: (payload) => ({
        url: URL.NOTIFICATION_REPORT,
        body: payload,
        method: "POST",
      }),
    }),
    notificationReportExport: builder.mutation({
      query: (payload) => ({
        url: URL.NOTIFICATION_REPORT_EXPORT,
        body: payload,
        method: "POST",
      }),
    }),

    viewDevice: builder.query({
      query: (id) => URL.DEVICE_VIEW + id,
    }),

    DeviceStatus: builder.query({
      query: (id) => URL.DEVICE_STATUS + id,
    }),

    //LOCATION

    locationList: builder.query({
      query: () => URL.LOCATION_LIST,
    }),

    locationStatus: builder.query({
      query: (id) => URL.LOCATION_STATUS + id,
    }),
  }),
});

export const {
  //AUTHENTICATION
  useLoginMutation,
  useLazyLogoutQuery,
  useForgotPasswordMutation,
  useLazyVerifyTokenQuery,
  useChangePasswordMutation,

  //ADMIN
  useAddAdminMutation,
  useLazyViewAdminQuery,
  useListAdminMutation,
  useLazyAdminStatusQuery,
  useLazyDeleteAdminQuery,
  useUpdateAdminMutation,

  //ROLE
  useLazyPermissionsListQuery,
  useListRoleMutation,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useLazyViewRoleQuery,
  useLazyDeleteRoleQuery,
  useLazyRoleStatusQuery,

  //USER LIST
  useListUserMutation,
  useAddUserMutation,
  useUpdateUserMutation,
  useLazyViewUserQuery,
  useLazyUserStatusQuery,

  //DEVICE LIST
  useDeviceListMutation,
  useAddDeviceMutation,
  useUpdateDeviceMutation,
  useLazyViewDeviceQuery,
  useLazyDeviceStatusQuery,
  useDeviceUserListMutation,
  useDeviceReportMutation,
  useDeviceReportExportMutation,
  useNotificationReportMutation,
  useNotificationReportExportMutation,

  //LOCATION
  useLazyLocationListQuery,
  useLazyLocationStatusQuery,
} = api;
