import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table/Table";
import {
  delete_icon,
  edit_icon,
  eye_icon,
  password_icon,
  toggle_off,
  toggle_on,
  view_icon,
} from "../../assets/imgs";
import { useLocation, useNavigate } from "react-router-dom";
import { admin_list } from "../redux/api/dummyJson";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import apiRequest from "../redux/api/apiRequest";
import {
  useDeviceReportExportMutation,
  useDeviceReportMutation,
} from "../redux/api/api";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { toast } from "react-toastify";
import moment from "moment/moment";

const Report = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [list, setList] = useState([]);
  const givenpermissions = JSON.parse(localStorage.getItem("user_permission"));
  const location = useLocation();
  const [totalcount, setTotalcount] = useState(0);
  const [DeviceData, setDeviceData] = useState("");
  const [date, setDate] = useState();
  const [page, setPage] = useState(1);
  const [Search, setSearch] = useState("");
  const [pagination, setPagination] = useState("");

  const today = moment();
  const [max, setMax] = useState(today.clone().toDate());
  //   console.log(location?.state?.data);
  const [deviceReport] = useDeviceReportMutation();
  const [deviceReportExport] = useDeviceReportExportMutation();

  const getList = async (id, search, FROM, TO) => {
    const formdata = new FormData();
    formdata.append("device_id", id);
    formdata.append("rows", 10);
    formdata.append("search", search ? search : "");
    formdata.append("order", "");
    formdata.append("page", page);
    if ((from && to) || (FROM && TO)) {
      formdata.append("start_date", FROM ? FROM : from);
      formdata.append("end_date", TO ? TO : to);
    }
    const res = await apiRequest(deviceReport, formdata);
    console.log(res);
    if (res?.status == "success") {
      setList(res?.lists);
      setPagination(res?.pagination_meta);
      setTotalcount(res?.report_count);
    }
  };
  const Export = async (id) => {
    const formdata = new FormData();
    formdata.append("device_id", id);
    formdata.append("search", Search);
    formdata.append("order", "");
    if (from && to) {
      formdata.append("start_date", from);
      formdata.append("end_date", to);
    }
    const res = await apiRequest(deviceReportExport, formdata);
    console.log(res);
    if (res?.status == "success") {
      if (res?.download_link) {
        const anchor = document.createElement("a");
        anchor.href = res.download_link;
        anchor.target = "_blank";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    }
  };

  useEffect(() => {
    let data = location?.state?.data;
    console.log("data", data);
    if (data?.id) {
      setDeviceData(data);
      getList(data?.id);
    }
  }, [page]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Date & Time",
        accessor: "current_time",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Siren (sec)",
        accessor: "siren_sec",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Flash Light (sec)",
        accessor: "lignt_sec",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Type of Forecast",
        accessor: "forecast",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Device Status",
        accessor: "device_status",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
    ],
    []
  );

  const formatDate = (value) => {
    setDate(value);
    let start = value.start;
    let end = value.end;
    let from = moment(start).clone().format("YYYY-MM-DD");
    let to = moment(end).clone().format("YYYY-MM-DD");
    if (from != "Invalid date" && to != "Invalid date") {
      console.log(from, to);
      getList(DeviceData?.id, "", from, to);
    }
    setFrom(from);
    setTo(to);
  };

  return (
    <div className="dashRightView home_section">
      {/* <div className='d-flex ac-jb'> */}
      <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
        Device Report
      </h2>
      <div className="d-flex ac-js">
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary mt-3 w-30">
          Device Name :{" "}
          <span className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary1">
            {DeviceData?.device_name}
          </span>
        </h2>
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary mt-3">
          Device Code :{" "}
          <span className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary1">
            {DeviceData?.device_code}
          </span>
        </h2>
      </div>
      <div className="d-flex ac-js">
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary mt-3 w-30">
          Report Count :{" "}
          <span className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary1">
            {totalcount}
          </span>
        </h2>
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary mt-3">
          Device Location :{" "}
          <span className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary1">
            {DeviceData?.location?.name ? DeviceData?.location?.name : "N/A"}
          </span>
        </h2>
      </div>

      <div className="d-flex ac-js mt-3 ">
        <div className="d-flex ac-js mt-3 ">
          <h4 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary me-3">
            Search
          </h4>
          <input
            type="text"
            className="input-pop ps-2"
            placeholder=" Search"
            onChange={(e) => {
              setSearch(e.target.value);
              getList(DeviceData?.id, e.target.value);
            }}
          />
        </div>
        <div className="d-flex ac-js mt-3 ms-5 ">
          <div className="d-flex ac-jc gap-2">
            <p className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22  primary me-3">
              Range
            </p>
            <DateRangePicker
              className={`f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22  primary me-3`}
              format="dd/MM/yyyy"
              value={date}
              max={max}
              onChange={(e) => {
                formatDate(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-flex ac-js mt-3 ">
          <button
            className="btn btn-danger ms-5"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </button>
        </div>
        <div className="d-flex ac-js mt-3 ">
          <button
            className="btn btn-success ms-5 px-4"
            onClick={() => {
              Export(DeviceData?.id);
            }}
          >
            <FileDownloadOutlinedIcon className="up-icon" />
            Export
          </button>
        </div>
      </div>

      <Table
        columns={columns}
        data={list}
        show={true}
        setPageno={setPage}
        pagecount={pagination}
      />
    </div>
  );
};

export default Report;
