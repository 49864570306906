import React, { useState, useEffect } from "react";
import { Table } from "../../components/Table/Table";
import {
  delete_icon,
  edit_icon,
  eye_icon,
  password_icon,
  profile,
  toggle_off,
  toggle_on,
  view_icon,
} from "../../assets/imgs";
import apiRequest from "../redux/api/apiRequest";
import { toast } from "react-toastify";
import { useDeviceUserListMutation } from "../redux/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { admin_list } from "../redux/api/dummyJson";
import CancelIcon from "@mui/icons-material/Cancel";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { EMAIL } from "../redux/api/Constants";

const MembersList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState("");
  const [device, setDevice] = useState("");
  const [page, setPage] = useState(1);
  const [ID, setId] = useState("");
  const [imageUrl, setImageUrl] = useState(false);
  const [deviceUserList] = useDeviceUserListMutation();

  const getList = async (id, search) => {
    const formdata = new FormData();
    formdata.append("device_id", id);
    formdata.append("rows", 10);
    formdata.append("page", page);
    formdata.append("search", search ? search : "");
    formdata.append("order", "asc");
    const res = await apiRequest(deviceUserList, formdata);
    if (res?.status == "success") {
      setList(res?.lists);
      setPagination(res?.pagination_meta);
    }
  };

  useEffect(() => {
    let data = location?.state?.data;
    console.log(data);
    if (data) {
      setDevice(data?.device_name);
      setId(data?.id);
      getList(data?.id);
    }
  }, [page]);
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Photo",
        accessor: "profile_url",
        Cell: ({ value, row }) => {
          return (
            <div
              onClick={() => {
                if (!value) {
                  return;
                }
                setImageUrl(value);
              }}
            >
              <img
                src={value ? value : profile}
                className="vip-image pointer"
              />
            </div>
          );
        },
      },
      {
        Header: " Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Number",
        accessor: "phone",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: " Email",
        accessor: "email",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: " City",
        accessor: "city",
        Cell: ({ value, row }) => {
          return <p>{value ? value : "--"}</p>;
        },
      },
      {
        Header: " state",
        accessor: "state",
        Cell: ({ value, row }) => {
          return <p>{value ? value : "--"}</p>;
        },
      },
      {
        Header: " Country",
        accessor: "country",
        Cell: ({ value, row }) => {
          return <p>{value ? value : "--"}</p>;
        },
      },
    ],
    []
  );

  return (
    <div className="dashRightView home_section">
      <div className="d-flex ac-jb">
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
          Member List
        </h2>
      </div>
      <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary mt-2 ">
        Device Name :{" "}
        <span className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary1">
          {device}
        </span>
      </h2>

      <div className="d-flex ac-js mt-2 ">
        <h4 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary me-3">
          Search
        </h4>
        <input
          type="text"
          className="input-pop ps-2"
          placeholder=" Search"
          onChange={(e) => {
            getList(ID, e.target.value);
          }}
        />
      </div>

      <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary mt-2 ">
        Member Count :{" "}
        <span className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary1">
          {list?.length < 10 ? `0${list?.length}` : list?.length}
        </span>
      </h2>
      <Table
        columns={columns}
        data={list}
        show={true}
        pagecount={pagination}
        setPageno={setPage}
      />

      {imageUrl && (
        <div className="image-popup-design">
          <div className="img-popup">
            <div
              className="d-flex ac-je w-100 pointer"
              onClick={() => setImageUrl(false)}
            >
              <HighlightOffIcon />
            </div>
            <img src={imageUrl} className="image-pop" alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MembersList;
